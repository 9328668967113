/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { emitEvent, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	assignParentToModelProps,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	ISchemaConstObject,
	ISchemaConstObjectModel,
	ISchemaConstObjectOptsProp,
	Prop,
	SchemaConstObject,
	TSchemaConstObjectProps
} from "./const/SchemaConstObject";
import { ISchemaConstStringModel, SchemaConstString } from "./const/SchemaConstString";
import { applyCodeArg, escapeString, inlineValue } from "../Context/CompileUtil";
import { TypeDescVoid } from "../Shared/ITypeDescriptor";
import { IDataSourceResolver, IEventResolver } from "../Resolvers";
import {
	IDataSourceDefinition,
	IDataSourceStateBase,
	TGenericDataSourceDefinition
} from "../DataSource/IDataSourceDefinition";
import { TGenericDataSourceInstance } from "../DataSource/IDataSourceInstance";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { ISchemaDataSourceListModel } from "./SchemaDataSourceList";
import { ISchemaScopedTemplate, SchemaScopedTemplate } from "./SchemaScopedTemplate";
import { ISchemaFlowNodeList, SchemaFlowNodeList } from "./SchemaFlowNodeList";
import { ISchemaFlowNodeTypeDefinitionMap } from "./SchemaFlowNode";
import { TGenericEventDefinitionMap } from "../Events/EventTypes";

export type TSchemaDataSourceIdModel = ISchemaConstStringModel;
export type TSchemaDataSourceOptsModel = ISchemaConstObjectModel<TSchemaConstObjectProps>;

export type TSchemaDataSourceEventHandler = ISchemaConstObject<{
	nodes: ISchemaConstObjectOptsProp<
		ISchemaScopedTemplate<ISchemaFlowNodeList<ISchemaFlowNodeTypeDefinitionMap>>
	>;
}>;

export type TSchemaDataSourceEvents = ISchemaConstObject<{
	[K: string]: ISchemaConstObjectOptsProp<TSchemaDataSourceEventHandler>;
}>;

export type TSchemaDataSourceEventsModel = TGetBlueprintSchemaModel<TSchemaDataSourceEvents>;

/**
 * Schema model
 */
export interface ISchemaDataSourceModel extends IModelNode<ISchemaDataSource> {
	dsType: string;
	dsHandler: IDataSourceDefinition<TSchemaConstObjectProps, IDataSourceStateBase, TGenericEventDefinitionMap>;
	id: TSchemaDataSourceIdModel;
	opts: TSchemaDataSourceOptsModel;
	events: TSchemaDataSourceEventsModel;
	parentDataSourceList: ISchemaDataSourceListModel;
	__updateIdIndex: () => void;
	__lastInstance?: TGenericDataSourceInstance;
}

/**
 * Schema spec
 */
export type TSchemaDataSourceSpec = TGenericDataSourceInstance;

/**
 * Schema defaults
 */
export interface ISchemaDataSourceDefault {
	type: string;
	id: string;
	opts: { [K: string]: unknown };
	events?: TGetBlueprintSchemaDefault<TSchemaDataSourceEvents>;
}

/**
 * Schema options
 */
export interface ISchemaDataSourceOpts extends IBlueprintSchemaOpts {}

/**
 * Schema create opts
 */
export interface ISchemaDataSourceCreateOpts {
	dataSourceListModel?: ISchemaDataSourceListModel;
}

/**
 * Schema type
 */
export interface ISchemaDataSource
	extends IBlueprintSchema<
		ISchemaDataSourceOpts,
		ISchemaDataSourceModel,
		TSchemaDataSourceSpec,
		ISchemaDataSourceDefault,
		ISchemaDataSourceCreateOpts
	> {
	/**
	 * Assigns data source to a list
	 *
	 * @param modelNode Model node
	 * @param listModel Data source list model
	 * @param notify If to notify about change
	 */
	assignToDataSourceList: (
		modelNode: ISchemaDataSourceModel,
		listModel: ISchemaDataSourceListModel,
		notify?: boolean
	) => void;

	/**
	 * Un-assigns data source from the list
	 *
	 * @param modelNode Model node
	 * @param notify If to notify about change
	 */
	unassignFromDataSourceList: (modelNode: ISchemaDataSourceModel, notify?: boolean) => void;

	/**
	 * Removes the data source from assigned list
	 *
	 * @param modelNode Model node
	 * @param notify If to notify about change
	 * @param destroy If to destroy self
	 */
	removeSelfFromDataSourceList: (
		modelNode: ISchemaDataSourceModel,
		notify?: boolean,
		destroy?: boolean
	) => void;

	/**
	 * Returns datasource instance from last render (if available)
	 */
	getLastInstance: (modelNode: ISchemaDataSourceModel) => TGenericDataSourceInstance;
}

/**
 * Schema: Data Source
 *
 * @param opts Schema options
 */
export function SchemaDataSource(opts: ISchemaDataSourceOpts): ISchemaDataSource {
	const schema = createEmptySchema<ISchemaDataSource>("dataSource", opts);

	const schemaId = SchemaConstString({
		label: "Data Source ID",
		constraints: {
			required: true,
			min: 1
		},
		fallbackValue: "undefinedId"
	});

	const createOptsSchema = (dsHandler: TGenericDataSourceDefinition) => {
		return SchemaConstObject({
			label: "Data Source properties",
			constraints: {
				required: true
			},
			props: dsHandler.opts
		});
	};

	const createEventsSchema = (
		dCtx: DesignContext,
		dsHandler: TGenericDataSourceDefinition,
	): TSchemaDataSourceEvents => {
		const eventProps: TSchemaConstObjectProps = {};

		const eventNodeTypes = dCtx.getResolver<IEventResolver>("componentEvent").getEventNodeTypes();

		for (const k in dsHandler.events) {
			const eventDef = dsHandler.events[k];

			eventProps[k] = Prop(
				SchemaConstObject({
					label: eventDef.label || k,
					description: eventDef.description,
					icon: eventDef.icon,
					alias: "componentEvent",
					props: {
						nodes: Prop(
							SchemaScopedTemplate({
								template: SchemaFlowNodeList({
									label: "Event flow",
									nodeTypes: eventNodeTypes,
									entryNode: {
										id: "eventStart",
										type: "eventStart",
										defaultPosition: {
											x: 0,
											y: 0
										},
										defaultOpts: {}
									}
								})
							})
						)
					}
				}),
				eventDef.order
			);
		}

		return SchemaConstObject({
			label: "Events",
			description: "Event handlers",
			props: eventProps
		}) as TSchemaDataSourceEvents;
	};

	const bindUpdateIdIndex = (model: ISchemaDataSourceModel) => {
		let lastId: string = null;

		const updateIdIndex = () => {
			if (model.id.value !== lastId) {
				if (lastId && model.id.value) {
					model.ctx.__renameIdentifier(lastId, model.id.value);
				} else if (lastId) {
					model.ctx.__removeIdentifier(lastId);
				} else if (model.id.value) {
					model.ctx.__addIdentifier(model.id.value);
				}

				lastId = model.id.value;
			}
		};

		onEvent(model.id.changeEvent, updateIdIndex);
		updateIdIndex();

		model.__updateIdIndex = updateIdIndex;
	};

	const keyPropRules = {
		id: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: schemaId.provideCompletion
		},
		type: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				const dsList = dCtx.getResolver<IDataSourceResolver>("dataSource").getList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = Object.keys(dsList).map((dsName) => ({
						kind: CMPL_ITEM_KIND.Class,
						label: dsName,
						insertText: dsName
					}));

					return items;
				});
			}
		},
		opts: {
			required: true
		},
		events: {
			required: false
		},
	};

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "id", "opts" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		dsType: string,
		dsHandler: TGenericDataSourceDefinition,
		models: {
			id: TSchemaDataSourceIdModel;
			opts: TSchemaDataSourceOptsModel;
			events: TSchemaDataSourceEventsModel;
		},
		parentDataSourceList: ISchemaDataSourceListModel,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			dsType: dsType,
			dsHandler: dsHandler,
			id: models.id,
			opts: models.opts,
			events: models.events,
			parentDataSourceList: parentDataSourceList,
			__updateIdIndex: null
		});

		const model = assignParentToChildrenOf(modelNode);

		bindUpdateIdIndex(model);

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {
		if (!defaultValue) {
			throw new SchemaDeclarationError(schema.name, schema.opts, "Default value must be defined.");
		}

		if (!(defaultValue instanceof Object)) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to be an object."
			);
		}

		if (!defaultValue.type) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Default value property 'type' must be defined."
			);
		}

		if (!defaultValue.id) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Defalt value property 'id' must be defined."
			);
		}

		const dsList = dCtx.getResolver<IDataSourceResolver>("dataSource").getList();
		const dsType = defaultValue.type;

		// Check and get data source handler
		const dsHandler = dsList[dsType];

		if (!dsHandler) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Data Source type '${dsType}' is not defined.`
			);
		}

		const optsSchema = createOptsSchema(dsHandler);
		const eventSchema = createEventsSchema(dCtx, dsHandler);
		
		const optsModel = optsSchema.createDefault(dCtx, null, defaultValue.opts);
		const eventsModel = eventSchema.createDefault(dCtx, null, defaultValue.events);

		// Create model
		return createModel(
			dCtx,
			dsType,
			dsHandler,
			{
				id: schemaId.createDefault(dCtx, null, defaultValue.id),
				opts: optsModel,
				events: eventsModel
			},
			createOpts?.dataSourceListModel || null,
			parent
		);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedModel = cloneModelNode(dCtx, modelNode, parent, {
			dsType: modelNode.dsType,
			dsHandler: modelNode.dsHandler,
			id: modelNode.id.schema.clone(dCtx, modelNode.id, null),
			opts: modelNode.opts.schema.clone(dCtx, modelNode.opts, null),
			events: modelNode.events.schema.clone(dCtx, modelNode.events, null),
			parentDataSourceList: null,
			__updateIdIndex: null
		});

		const clone = assignParentToChildrenOf(clonedModel);

		bindUpdateIdIndex(clone);

		return clone;
	};

	schema.destroy = (modelNode) => {
		if (modelNode.__updateIdIndex) {
			offEvent(modelNode.id.changeEvent, modelNode.__updateIdIndex);

			if (modelNode.id.value) {
				modelNode.ctx.__removeIdentifier(modelNode.id.value);
			}
		}

		modelNode.dsType = undefined;
		modelNode.dsHandler = undefined;

		modelNode.id.schema.destroy(modelNode.id);
		modelNode.opts.schema.destroy(modelNode.opts);
		modelNode.events.schema.destroy(modelNode.events);

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent, createOpts) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: true,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return null;
		}

		// Extract keys
		const { keys, keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		if (!keysValid.id || !keysValid.type) {
			return null;
		}

		// ---

		const dsList = dCtx.getResolver<IDataSourceResolver>("dataSource").getList();
		const dsType = (keys.type.value as IBlueprintIDTScalar).value as string;

		// Check and get data source handler
		const dsHandler = dsList[dsType];

		if (!dsHandler) {
			if (keys["type"].value.parseInfo) {
				dCtx.logParseError(keys["type"].value.parseInfo.loc.uri, {
					range: keys["type"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.DS_UNKNOWN,
					message: `Unknown Data Source type '${dsType}'.`,
					parsePath: keys["type"].value.path
				});
			}

			return null;
		}

		const optsSchema = createOptsSchema(dsHandler);
		const eventSchema = createEventsSchema(dCtx, dsHandler);

		// Provide completions
		provideIDTMapPropertyCompletions(dCtx, rootNode, keys, {
			opts: optsSchema.provideCompletion,
			events: eventSchema.provideCompletion
		});

		// Parse options
		const optsModel =
			keys["opts"] && keys["opts"].value
				? optsSchema.parse(dCtx, keys["opts"].value, null)
				: optsSchema.createDefault(dCtx, parent);

		const eventsModel =
			keys["events"] && keys["events"].value
				? eventSchema.parse(dCtx, keys["events"].value, null)
				: eventSchema.createDefault(dCtx, null);

		// Create model
		return createModel(
			dCtx,
			dsType,
			dsHandler,
			{
				id: schemaId.parse(dCtx, keys["id"].value, null),
				opts: optsModel,
				events: eventsModel
			},
			createOpts?.dataSourceListModel || null,
			parent
		);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// type
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[type]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{type}" ]),
						value: "type"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "type" ]),
						value: modelNode.dsType
					} as IBlueprintIDTScalar
				} as IBlueprintIDTMapElement,
				// id
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[id]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{id}" ]),
						value: "id"
					} as IBlueprintIDTScalar,
					value: modelNode.id.schema.serialize(modelNode.id, path.concat([ "id" ]))
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[opts]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{opts}" ]),
						value: "opts"
					} as IBlueprintIDTScalar,
					value: modelNode.opts.schema.serialize(modelNode.opts, path.concat([ "opts" ]))
				} as IBlueprintIDTMapElement,
				// events
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[events]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{events}" ]),
						value: "events"
					} as IBlueprintIDTScalar,
					value: modelNode.events.schema.serialize(modelNode.events, path.concat([ "events" ]))
				} as IBlueprintIDTMapElement,
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		rCtx.__beginLocalBoundary();

		const _prevSpec = prevSpec instanceof Object ? prevSpec : ({} as TGenericDataSourceInstance);

		// Resolve ID and opts
		const id = modelNode.id.schema.render(rCtx, modelNode.id, path.concat([ "id" ]), scope, _prevSpec.id);
		const opts = modelNode.opts.schema.render(
			rCtx,
			modelNode.opts,
			path.concat([ "opts" ]),
			scope,
			_prevSpec.opts
		);

		// Resolve events
		const events = modelNode.events.schema.render(
			rCtx,
			modelNode.events,
			path.concat([ "events" ]),
			scope,
			_prevSpec.eventsSpec
		);
		const eventsEnabled: { [K: string]: boolean } = {};

		for (const k in modelNode.events.props) {
			// eslint-disable-next-line max-len
			const entryNode = modelNode.events.props[k].props.nodes.template.schema.getEntryNode(
				modelNode.events.props[k].props.nodes.template
			);
			eventsEnabled[k] = entryNode.outputs.props["onEvent"]?.items.length > 0;
		}

		const localBoundary = rCtx.__endLocalBoundary();

		try {
			const ret = modelNode.dsHandler.render(
				rCtx,
				{
					type: modelNode.dsType,
					modelNodeId: modelNode.nodeId,
					id: id,
					opts: opts,
					events: events,
					eventsEnabled: eventsEnabled,
					hasErrors: localBoundary.error > 0,
					isLoading: localBoundary.isLoading > 0,
					isLoadingWithData: localBoundary.isLoadingWithData === localBoundary.isLoading,
					modelNode: modelNode
				},
				path,
				scope,
				prevSpec
			);

			modelNode.__lastInstance = ret;

			return ret;
		} catch (err) {
			rCtx.logRuntimeError({
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.DS_RUNTIME_ERROR,
				message: "Failed to process data source: " + String(err),
				modelPath: path,
				modelNodeId: modelNode.nodeId
			});

			console.warn("Failed to process data source:", err);

			return null;
		}
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Compile child props
		const id = modelNode.id.schema.compileRender(cCtx, modelNode.id, path.concat([ "id" ]));
		const opts = modelNode.opts.schema.compileRender(cCtx, modelNode.opts, path.concat([ "opts" ]));

		const events = modelNode.events.schema.compileRender(
			cCtx,
			modelNode.events,
			path.concat([ "events" ])
		);
		const eventsEnabled: { [K: string]: boolean } = {};

		for (const k in modelNode.events.props) {
			// eslint-disable-next-line max-len
			const entryNode = modelNode.events.props[k].props.nodes.template.schema.getEntryNode(
				modelNode.events.props[k].props.nodes.template
			);
			eventsEnabled[k] = entryNode.outputs.props["onEvent"]?.items.length > 0;
		}

		const statements = [
			// Resolve data source handler
			`const _ch=rCtx.getResolver("dataSource").getByType("${escapeString(modelNode.dsType)}");`,
			// eslint-disable-next-line max-len
			`if(!_ch){rCtx.logRuntimeError({severity:${inlineValue(
				DOC_ERROR_SEVERITY.ERROR
			)},name:${inlineValue(
				DOC_ERROR_NAME.DS_UNKNOWN
			)},message:"Unknown Data Source type '"+"${escapeString(
				modelNode.dsType
			)}"+"'.",modelPath:pt,modelNodeId:${inlineValue(modelNode.nodeId)}});return null}`,
			// Define prev spec
			`const _ps=(pv&&typeof pv==="object"&&pv!==null?pv:{});`,
			// Begin boundary
			`rCtx.__beginLocalBoundary();`,
			// Resolve props
			`const _op=${applyCodeArg(opts, `_ps.opts`, `pt.concat(["opts"])`)};`,
			// Resolve events
			`const _ev=${applyCodeArg(events, `_ps.__eventsSpec`, `pt.concat(["events"])`)};`,
			`const _eve=${inlineValue(eventsEnabled)};`,
			// End boundary
			`const _lb=rCtx.__endLocalBoundary();`,
			// Call handler
			`try{`,
			// eslint-disable-next-line max-len
			`return _ch.render(rCtx,{type:"${escapeString(modelNode.dsType)}",modelNodeId:${inlineValue(
				modelNode.nodeId
			)},id:${applyCodeArg(
				id,
				`_ps.id`,
				`pt.concat(["id"])`
			)},opts:_op,events:_ev,eventsEnabled:_eve,hasErrors:_lb.errors>0,isLoading:_lb.isLoading>0,isLoadingWithData:_lb.isLoadingWithData===_lb.isLoading},pt,s,pv)`,
			// eslint-disable-next-line max-len
			`}catch(e){rCtx.logRuntimeError({severity:${inlineValue(
				DOC_ERROR_SEVERITY.ERROR
			)},name:${inlineValue(
				DOC_ERROR_NAME.DS_RUNTIME_ERROR
			)},message:"Failed to process data source: "+String(e),modelPath:pt,modelNodeId:${inlineValue(
				modelNode.nodeId
			)}});return null}`
		];

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${statements.join("")}}`
		};
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaDataSource", [ opts ]);
	};

	// Type descriptor here is irrelevant, because data source exports to scope on it's own
	schema.getTypeDescriptor = () => {
		return TypeDescVoid({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.assignToDataSourceList = (
		modelNode: ISchemaDataSourceModel,
		listNode: ISchemaDataSourceListModel,
		notify?: boolean
	) => {
		// Assing data source list
		modelNode.parentDataSourceList = listNode;

		// Notify
		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.unassignFromDataSourceList = (modelNode: ISchemaDataSourceModel, notify?: boolean) => {
		// Unassing parent component list
		modelNode.parentDataSourceList = null;

		// Notify
		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.removeSelfFromDataSourceList = (
		modelNode: ISchemaDataSourceModel,
		notify?: boolean,
		destroy?: boolean
	) => {
		if (modelNode.parentDataSourceList) {
			modelNode.parentDataSourceList.schema.removeItemByModel(
				modelNode.parentDataSourceList,
				modelNode,
				notify
			);
		}

		if (destroy) {
			modelNode.schema.destroy(modelNode);

			if (notify) {
				emitEvent(modelNode.ctx.modelChangeEvent);
			}
		}
	};

	schema.getLastInstance = (modelNode: ISchemaDataSourceModel) => {
		return modelNode.__lastInstance ?? null;
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "id",
				node: modelNode.id
			},
			{
				key: "opts",
				node: modelNode.opts
			},
			{
				key: "events",
				node: modelNode.events
			}
		];
	};

	return schema;
}
