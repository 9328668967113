/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	OBJECT_TYPE,
	OBJECT_TYPE_PROP_NAME,
	createSubScope,
	TGetBlueprintSchemaSpec,
	ISchemaConstObject,
	SCHEMA_CONST_ANY_VALUE_TYPE,
} from "@hexio_io/hae-lib-blueprint";

/**
 * WebSocket Client Datasource Internal State
 */
enum DS_WEBSOCKET_STATE {
	DISABLED = "DISABLED",
	DISCONNECTED = "DISCONNECTED",
	CONNECTING = "CONNECTING",
	CONNECTED = "CONNECTED",
}

enum DS_WEBSOCKET_MODE {
	TEXT = "TEXT",
	BINARY = "BINARY",
	JSON = "JSON"
}

interface DataSourceWebSocketClient_State {
	// Config
	url: string;
	mode: DS_WEBSOCKET_MODE;
	enabled: boolean;
	shouldReconnect: boolean;
	maxReconnectAttempts: number;
	baseReconnectDelay: number;
	maxReconnectDelay: number;
	bufferOutgoingMessages: boolean;
	flushMessageBufferOnConnect: boolean;

	// Internal
	ws: WebSocket|null;
	isReconnecting: boolean;
	reconnectTimeout: ReturnType<typeof setTimeout>|null;
	handleOnOpen: (() => void)|null;
	handleOnClose: (() => void)|null;
	handleOnError: ((err: unknown) => void)|null;
	handleOnMessage: ((message: unknown) => void)|null;
	handleOnVisibilityChange: (() => void)|null;
	handleOnNetworkChange: (() => void)|null;
	messageBuffer: unknown[];

	// Public
	state: DS_WEBSOCKET_STATE;
	reconnectAttempts: number;
	lastError: {
		name: string;
		message: string;
	}|null;
	sendMessage: (message: unknown) => void;
	clearBuffer: () => void;
	flushBuffer: () => void;
	tryReconnect: () => void;
}

export const DataSourceWebSocketClient_Opts = {
	url: BP.Prop(
		BP.String({
			label: 'URL',
			description: 'WebSocket URL, must begin with ws:// or wss://',
			constraints: {
				required: false
			}
		})
	),
	mode: BP.Prop(
		BP.Enum.String({
			label: 'Mode',
			description: 'Binary messages are base64 encoded both in On Message event and in Send Message method. In JSON mode, the incoming message is parsed as a JSON object and the outgoing message is stringified.',
			options: [
				{
					value: DS_WEBSOCKET_MODE.TEXT,
					label: 'Text'
				},
				{
					value: DS_WEBSOCKET_MODE.BINARY,
					label: 'Binary (base64 encoded)'
				},
				{
					value: DS_WEBSOCKET_MODE.JSON,
					label: 'JSON'
				}
			],
			default: DS_WEBSOCKET_MODE.TEXT
		})
	),
	enabled: BP.Prop(
		BP.Boolean({
			label: "Enabled",
			description: "Enable or disable the WebSocket client.",
			constraints: {
				required: true
			},
			default: true,
			fallbackValue: null
		})
	),
	reconnect: BP.Prop(
		BP.OptGroup({
			label: 'Reconnect',
			description: 'Reconnect options',
			value: BP.Object({
				props: {
					maxAttempts: BP.Prop(
						BP.Integer({
							label: 'Max attempts',
							description: 'Number of attempts to reconnect to the server. O means infinite.',
							constraints: {
								required: false,
								min: 0
							},
							default: 0,
							fallbackValue: 0
						})
					),
					baseDelay: BP.Prop(
						BP.Integer({
							label: 'Base delay',
							description: 'Base delay in milliseconds between reconnect attempts.',
							constraints: {
								required: false,
								min: 0
							},
							default: 1000,
							fallbackValue: 1000
						})
					),
					maxDelay: BP.Prop(
						BP.Integer({
							label: 'Max delay',
							description: 'Maximum delay in milliseconds between reconnect attempts.',
							constraints: {
								required: false,
								min: 0
							},
							default: 30000,
							fallbackValue: 30000
						})
					)
				},
				editorOptions: {
					layoutType: 'passthrough'
				}
			}),
			enabledOpts: {
				default: true
			}
		})
	),
	bufferOutgoingMessages: BP.Prop(
		BP.Boolean({
			label: 'Buffer outgoing messages',
			description: 'Buffer outgoing messages when the connection is not established. Messages are sent in order when the connection is established. May use more memory.',
			constraints: {
				required: false
			},
			default: false,
			fallbackValue: false
		})
	),
	flushMessageBufferOnConnect: BP.Prop(
		BP.Boolean({
			label: 'Flush message buffer on connect',
			// eslint-disable-next-line max-len
			description: 'When enabled, messages in buffer will be automatically sent when the connection is established. Disable this option if you need to send other message before, eg. to perform handshake. Then, call flushBuffer method manually.',
			constraints: {
				required: false
			},
			default: true,
			fallbackValue: true
		})
	)
};

type Opts = TGetBlueprintSchemaSpec<ISchemaConstObject<typeof DataSourceWebSocketClient_Opts>>;

export const DataSourceWebSocketClient_Events = {
	connect: {
		label: 'On Connect',
		icon: "mdi/lan-connect"
	},
	disconnect: {
		label: 'On Disconnect',
		icon: "mdi/lan-disconnect"
	},
	message: {
		label: 'On Message',
		icon: "mdi/message-text"
	},
	error: {
		label: 'On Error',
		icon: "mdi/alert"
	}
};

/**
 * State functions
 */
const InitialState = {
	ws: null,
	isReconnecting: false,
	reconnectTimeout: null,
	handleOnOpen: null,
	handleOnClose: null,
	handleOnError: null,
	handleOnMessage: null,
	handleOnVisibilityChange: null,
	handleOnNetworkChange: null,
	state: DS_WEBSOCKET_STATE.DISABLED,
	reconnectAttempts: 0,
	lastError: null,
	messageBuffer: []
}

function updateConfigInState(prevState: DataSourceWebSocketClient_State, opts: Opts): DataSourceWebSocketClient_State {
	return {
		...prevState,
		url: opts.url,
		mode: opts.mode as DS_WEBSOCKET_MODE,
		enabled: opts.enabled,
		shouldReconnect: opts.reconnect !== null,
		maxReconnectAttempts: opts.reconnect?.maxAttempts ?? 0,
		baseReconnectDelay: opts.reconnect?.baseDelay ?? 0,
		maxReconnectDelay: opts.reconnect?.maxDelay ?? 0,
		bufferOutgoingMessages: opts.bufferOutgoingMessages,
		flushMessageBufferOnConnect: opts.flushMessageBufferOnConnect,
	};
}

function destroyConnection(prevState: DataSourceWebSocketClient_State): DataSourceWebSocketClient_State {
	if (prevState.ws) {
		prevState.ws.onopen = null;
		prevState.ws.onmessage = null;
		prevState.ws.onerror = null;
		prevState.ws.onclose = null;
		prevState.ws.close();
	}

	if (prevState.reconnectTimeout) {
		clearTimeout(prevState.reconnectTimeout);
	}

	return {
		...prevState,
		state: DS_WEBSOCKET_STATE.DISCONNECTED,
		ws: null,
		isReconnecting: false,
		reconnectTimeout: null,
	};
}

function connect(prevState: DataSourceWebSocketClient_State): DataSourceWebSocketClient_State {
	const state = destroyConnection(prevState);

	if (!navigator.onLine) {
		console.debug("Offline. Waiting for network to reconnect...");

		return {
			...state,
			state: DS_WEBSOCKET_STATE.DISCONNECTED
		};
	}

	// Create WebSocket connection
	state.ws = new WebSocket(prevState.url);

	if (prevState.mode === DS_WEBSOCKET_MODE.BINARY) {
		state.ws.binaryType = 'arraybuffer';
	}

	state.ws.onopen = () => {
		console.debug("WebSocket Client: Connected");
		prevState.handleOnOpen?.();
	};

	state.ws.onmessage = (event) => {
		let message: unknown;

		switch (prevState.mode) {
			case DS_WEBSOCKET_MODE.TEXT: {
				message = event.data;
				break;
			}
			case DS_WEBSOCKET_MODE.JSON: {
				try {
					message = JSON.parse(event.data);
				} catch (e) {
					console.error("WebSocket Client: Error parsing JSON message", e, event.data);
					prevState.handleOnError?.("Error parsing JSON message.");
					return;
				}
				break;
			}
			case DS_WEBSOCKET_MODE.BINARY: {
				message = btoa(new Uint8Array(event.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
			}
		}

		prevState.handleOnMessage?.(message);
	};

	state.ws.onerror = (error) => {
		console.error("WebSocket Client: Error", error);
		prevState.handleOnError?.("WebSocket operation error. See console for details.");
	};

	state.ws.onclose = () => {
		console.debug("WebSocket Client: Disconnected");
		prevState.handleOnClose?.();
	};

	state.state = DS_WEBSOCKET_STATE.CONNECTING;

	return state;
}

function addMessageToBuffer(prevState: DataSourceWebSocketClient_State, message: unknown): DataSourceWebSocketClient_State {
	return {
		...prevState,
		messageBuffer: [
			...prevState.messageBuffer,
			message
		]
	};
}

function clearBuffer(prevState: DataSourceWebSocketClient_State): DataSourceWebSocketClient_State {
	return {
		...prevState,
		messageBuffer: []
	};
}

function validateMessageOrThrow(state: DataSourceWebSocketClient_State, message: unknown): void {
	switch (state.mode) {
		case DS_WEBSOCKET_MODE.BINARY: {
			if (typeof message !== "string") {
				throw new Error("WebSocket Client: Binary mode only accepts base64-encoded string messages.");
			}
	
			break;
		}
		case DS_WEBSOCKET_MODE.JSON: {
			break;
		}
		case DS_WEBSOCKET_MODE.TEXT: {
			if (typeof message !== "string") {
				throw new Error("WebSocket Client: Text mode only accepts string messages.");
			}
			break;
		}
	}
}

function sendMessage(state: DataSourceWebSocketClient_State, message: unknown): void {
	switch (state.mode) {
		case DS_WEBSOCKET_MODE.BINARY: {
			if (typeof message !== "string") {
				throw new Error("WebSocket Client: Binary mode only accepts string messages.");
			}
	
			// Create binary payload from base64 encoded message
			const binaryPayload = Uint8Array.from(atob(message), c => c.charCodeAt(0));
			state.ws?.send(binaryPayload);
			break;
		}
		case DS_WEBSOCKET_MODE.JSON: {
			state.ws?.send(JSON.stringify(message));
			break;
		}
		case DS_WEBSOCKET_MODE.TEXT: {
			if (typeof message !== "string") {
				throw new Error("WebSocket Client: Text mode only accepts string messages.");
			}

			state.ws?.send(message);
			break;
		}
	}
}

function flushMessageBuffer(prevState: DataSourceWebSocketClient_State): DataSourceWebSocketClient_State {
	if (prevState.state !== DS_WEBSOCKET_STATE.CONNECTED) {
		console.warn("WebSocket Client: Cannot flush message buffer, WebSocket is not connected.");
		return prevState;
	}

	console.debug("WebSocket Client: Flushing message buffer (%d messages)", prevState.messageBuffer.length);

	prevState.messageBuffer.forEach(message => {
		sendMessage(prevState, message);
	});

	return {
		...prevState,
		messageBuffer: []
	}
}

/**
 * WebSocket Client Datasource
 */
export const DataSourceWebSocketClient = defineElementaryDataSource<
	typeof DataSourceWebSocketClient_Opts,
	DataSourceWebSocketClient_State,
	typeof DataSourceWebSocketClient_Events
>({
	name: "webSocketClient",
	label: "WebSocket Client",
	description: "WebSocket client allows you to connect to a WebSocket server and send and receive messages.",
	icon: "mdi/connection",
	opts: DataSourceWebSocketClient_Opts,
	events: DataSourceWebSocketClient_Events,
	resolve: (opts, prevState, updateStateAsync, dsInstance, rCtx) => {
		// Disable in SSR
		if (rCtx.isInSSR()) {
			return {
				...InitialState,
				...updateConfigInState(prevState, opts),
			};
		}

		let state = prevState;

		// Initialize state
		if (!state) {
			state = {
				...InitialState,
				...updateConfigInState(prevState, opts),
				handleOnOpen: () => {
					dsInstance.setState(prevState => ({
						...prevState,
						reconnectAttempts: 0,
						lastError: null,
						state: DS_WEBSOCKET_STATE.CONNECTED
					}));

					if (dsInstance.eventEnabled.connect) {
						dsInstance.eventTriggers.connect(scope => scope);
					}

					if (dsInstance.state.flushMessageBufferOnConnect) {
						updateStateAsync(prevState => flushMessageBuffer(prevState));
					}
				},
				handleOnClose: () => {
					const _state = dsInstance.state;

					if (_state.enabled && _state.shouldReconnect && !_state.isReconnecting) {
						if (_state.maxReconnectAttempts > 0 && _state.reconnectAttempts >= _state.maxReconnectAttempts) {
							dsInstance.setState(prevState => ({
								...prevState,
								lastError: {
									name: "MAX_RECONNECT_ATTEMPTS_REACHED",
									message: "Max reconnect attempts reached."
								},
								state: DS_WEBSOCKET_STATE.DISCONNECTED
							}));

							return;
						}

						const backoff = Math.min(
							_state.baseReconnectDelay * Math.pow(2, _state.reconnectAttempts),
							_state.maxReconnectDelay
						);
						const jitter = Math.random() * 100;

						const delay = backoff + jitter;

						dsInstance.setState(prevState => ({
							...prevState,
							isReconnecting: true,
							reconnectAttempts: prevState.reconnectAttempts + 1,
							reconnectTimeout: setTimeout(() => {
								dsInstance.setState(prevState => connect(prevState));
							}, delay),
							state: DS_WEBSOCKET_STATE.DISCONNECTED
						}));

						console.log(`Reconnecting in ${delay}ms... (Attempt ${_state.reconnectAttempts + 1})`);
					} else {
						dsInstance.setState(prevState => ({
							...prevState,
							isReconnecting: false,
							reconnectAttempts: 0,
							reconnectTimeout: null,
							state: DS_WEBSOCKET_STATE.DISCONNECTED
						}));						
					}

					if (dsInstance.eventEnabled.disconnect) {
						dsInstance.eventTriggers.disconnect(scope => scope);
					}
				},
				handleOnError: (error: string) => {
					dsInstance.setState(prevState => ({
						...prevState,
						lastError: {
							name: "SOCKET_ERROR",
							message: error
						},
						state: DS_WEBSOCKET_STATE.DISCONNECTED
					}));

					if (dsInstance.eventEnabled.error) {
						dsInstance.eventTriggers.error(scope => createSubScope(scope, { _error: error }));
					}
				},
				handleOnMessage: (message: unknown) => {
					if (dsInstance.eventEnabled.message) {
						dsInstance.eventTriggers.message(scope => createSubScope(scope, { _message: message }));
					}
				},
				handleOnVisibilityChange: () => {
					if (dsInstance.state && document.visibilityState === 'visible') {
						if (dsInstance.state.enabled && dsInstance.state.state === DS_WEBSOCKET_STATE.DISCONNECTED) {
							updateStateAsync(prevState => connect(prevState));
						}
					}
				},
				handleOnNetworkChange: () => {
					if (dsInstance.state && navigator.onLine) {
						if (dsInstance.state.enabled && dsInstance.state.state === DS_WEBSOCKET_STATE.DISCONNECTED) {
							updateStateAsync(prevState => connect(prevState));
						}
					} else {
						updateStateAsync(prevState => destroyConnection(prevState));
					}
				},
				sendMessage: (message: unknown) => {
					validateMessageOrThrow(state, message);

					if (dsInstance.state.state === DS_WEBSOCKET_STATE.CONNECTED) {
						sendMessage(dsInstance.state, message);
					} else if (dsInstance.state.bufferOutgoingMessages) {
						updateStateAsync(prevState => addMessageToBuffer(prevState, message));
					} else {
						throw new Error("WebSocket is not connected.");
					}
				},
				clearBuffer: () => {
					updateStateAsync(prevState => clearBuffer(prevState));
				},
				flushBuffer: () => {
					updateStateAsync(prevState => flushMessageBuffer(prevState))
				},
				tryReconnect: () => {
					if (
						dsInstance.state.enabled &&
						dsInstance.state.state === DS_WEBSOCKET_STATE.DISCONNECTED &&
						!dsInstance.state.isReconnecting
					) {
						updateStateAsync(prevState => connect(prevState));
					}
				}
			};

			document.addEventListener('visibilitychange', state.handleOnVisibilityChange);
			window.addEventListener('online', state.handleOnNetworkChange);
			window.addEventListener('offline', state.handleOnNetworkChange);
		}

		// Determine target state
		const shouldBeConnected = opts.enabled && opts.url;
		const hasConfigChanged = (
			state?.url !== opts.url ||
			state?.mode !== opts.mode ||
			state?.enabled !== opts.enabled ||
			state?.maxReconnectAttempts !== (opts.reconnect?.maxAttempts ?? 0) ||
			state?.baseReconnectDelay !== (opts.reconnect?.baseDelay ?? 0) ||
			state?.maxReconnectDelay !== (opts.reconnect?.maxDelay ?? 0) ||
			state?.bufferOutgoingMessages !== opts.bufferOutgoingMessages ||
			state?.flushMessageBufferOnConnect !== opts.flushMessageBufferOnConnect
		);

		// Update config
		if (hasConfigChanged) {
			state = {
				...state,
				...updateConfigInState(state, opts)
			}
		}

		// Should disconnect
		if (!shouldBeConnected && state.state !== DS_WEBSOCKET_STATE.DISABLED) {
			return {
				...destroyConnection(state),
				state: DS_WEBSOCKET_STATE.DISABLED
			};
		}

		// Should (re)connect
		if (shouldBeConnected && (!state.ws || hasConfigChanged) && !state.isReconnecting) {
			return connect(state);
		}

		// Otherwise no change
		return state;
	},

	destroy: (_opts, state) => {
		destroyConnection(state);

		if (state.handleOnVisibilityChange) {
			document.removeEventListener('visibilitychange', state.handleOnVisibilityChange);
		}

		if (state.handleOnNetworkChange) {
			window.removeEventListener('online', state.handleOnNetworkChange);
			window.removeEventListener('offline', state.handleOnNetworkChange);
		}

		state.handleOnClose = null;
		state.handleOnError = null;
		state.handleOnMessage = null;
		state.handleOnOpen = null;
		state.handleOnVisibilityChange = null;
		state.handleOnNetworkChange = null;

		state.messageBuffer = [];
	},

	getScopeData: (opts, state) => {
		return {
			[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE,
			state: state.state,
			reconnectAttempts: state.reconnectAttempts,
			lastError: state.lastError,
			messagesInBuffer: state.messageBuffer.length,
			sendMessage: state.sendMessage,
			clearBuffer: state.clearBuffer,
			flushBuffer: state.flushBuffer,
			tryReconnect: state.tryReconnect
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				state: Type.String({
					label: "State",
					description: "Client state"
				}),
				reconnectAttempts: Type.Integer({
					label: "Reconnect attempts",
					description: "Number of reconnect attempts"
				}),
				lastError: Type.String({
					label: "Last Error",
					description: "Last error message"
				}),
				messageInBuffer: Type.Integer({
					label: "Messages in Buffer",
					description: "Number of messages in buffer"
				}),
				sendMessage: Type.Method({
					label: "Send Message",
					description: "Send message to the WebSocket server",
					argRequiredCount: 1,
					argSchemas: [
						BP.Any({
							label: "Message",
							description: "Message to send",
							constraints: {
								required: true
							},
							defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
						})
					],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearBuffer: Type.Method({
					label: "Clear Buffer",
					description: "Clear outgoing message buffer",
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				flushBuffer: Type.Method({
					label: "Flush Buffer",
					description: "Send all messages from buffer",
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				tryReconnect: Type.Method({
					label: "Try Reconnect",
					description: "Try to reconnect to the WebSocket server",
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});
