/**
 * Property Item HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Type, BP, SCHEMA_VALUE_TYPE, defineElementaryComponent, createSubScope  } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumValue,
	getValuesFromStringEnum,
	IconSchema,
	IconSizeSchema,
	Label,
	TextSchema,
	THAEComponentDefinition,
	THAEComponentReact,
	termsEditor as HAELibComponentsTerms,
	propGroups,
	HORIZONTAL_ALIGN,
	HORIZONTAL_ALIGN_default,
	dimensionPattern,
	HORIZONTAL_ALIGN_opts,
	IconProps,
	LABEL_POSITION,
	LABEL_POSITION_default,
	LabelPositionSchema
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { LabelProps, LabelSchema } from "@hexio_io/hae-lib-components/src/SharedSchemas/Label";
import { PROPERTY_ITEM_STYLE, PROPERTY_ITEM_STYLE_default } from "../../Enums/PROPERTY_ITEM_STYLE";
import {
	PROPERTY_ITEM_VALUE_MODE,
	PROPERTY_ITEM_VALUE_MODE_default
} from "../../Enums/PROPERTY_ITEM_VALUE_MODE";
import { PropertyItemValue, IPropertyItemValueClassLists } from "./PropertyItemValue";
import { PropertyItemAdditionalText } from "./PropertyItemAdditionalText";
import { isNonEmptyObject } from "@hexio_io/hae-lib-shared";
import { BaseEvents } from "../../base/BaseEvents";

interface HAEComponentPropertyItem_State {
	value: string;
}

const ItemBaseProps = {
	copy: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.propertyItem.copy
		}),
		10
	),

	mode: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.propertyItem.mode,
			options: getValuesFromStringEnum(
				PROPERTY_ITEM_VALUE_MODE,
				termsEditor.schemas.propertyItem.modeValues
			),
			default: PROPERTY_ITEM_VALUE_MODE_default
		}),
		20
	),

	prefixText: BP.Prop(
		TextSchema({
			...termsEditor.schemas.propertyItem.prefixText,
			props: {
				overflow: BP.Prop(BP.Void({}))
			},
			editorOptions: {
				defaultExpanded: false
			}
		}),
		30
	),

	prefixWidth: BP.Prop(
		BP.String({
			...termsEditor.schemas.propertyItem.prefixWidth,
			constraints: {
				...HAELibComponentsTerms.schemas.common.dimensionConstraints,
				pattern: dimensionPattern
			}
		}),
		40
	),

	suffixText: BP.Prop(
		TextSchema({
			...termsEditor.schemas.propertyItem.suffixText,
			props: {
				overflow: BP.Prop(BP.Void({}))
			},
			editorOptions: {
				defaultExpanded: false
			}
		}),
		50
	),

	suffixWidth: BP.Prop(
		BP.String({
			...termsEditor.schemas.propertyItem.suffixWidth,
			constraints: {
				...HAELibComponentsTerms.schemas.common.dimensionConstraints,
				pattern: dimensionPattern
			}
		}),
		60
	)
};

const HAEComponentPropertyItem_Props = {
	label: BP.Prop(
		LabelSchema({
			props: {
				text: BP.Prop(
					TextSchema({
						props: {
							align: BP.Prop(BP.Void({})),

							overflow: BP.Prop(BP.Void({}))
						}
					}),
					LabelProps.text.order
				),

				icon: BP.Prop(
					IconSchema({
						props: {
							size: BP.Prop(
								IconSizeSchema({
									default: "SMALL"
								}),
								IconProps.size.order
							)
						}
					}),
					LabelProps.icon.order
				),

				horizontalAlign: BP.Prop(
					BP.Enum.String({
						...HAELibComponentsTerms.schemas.common.horizontalAlign,
						options: getValuesFromStringEnum(
							HORIZONTAL_ALIGN,
							HAELibComponentsTerms.schemas.common.horizontalAlignValues,
							HORIZONTAL_ALIGN_opts
						),
						default: HORIZONTAL_ALIGN_default,
						editorOptions: {
							controlType: "buttons"
						}
					}),
					20
				),

				// positionData: BP.Prop(LabelPosition()),

				position: BP.Prop(
					BP.Enum.String({
						...HAELibComponentsTerms.schemas.common.labelPosition,
						options: getValuesFromStringEnum(
							LABEL_POSITION,
							HAELibComponentsTerms.schemas.common.labelPositionValues
						),
						default: LABEL_POSITION_default
					}),
					30
				),

				width: BP.Prop(
					BP.String({
						...HAELibComponentsTerms.schemas.common.labelWidth,
						default: "200px",
						fallbackValue: "200px"
					}),
					40
				)
			},

			editorOptions: {
				layoutType: "passthrough"
			}
		}),
		10,
		propGroups.common
	),

	items: BP.Prop(
		BP.Array({
			...termsEditor.schemas.propertyItem.items,
			items: BP.Object({
				props: {
					...ItemBaseProps,

					text: BP.Prop(
						TextSchema({
							...termsEditor.schemas.propertyItem.text,
							props: {
								overflow: BP.Prop(BP.Void({}))
							}
						}),
						0
					)
				},
				editorOptions: {
					layoutType: "noHeader"
				}
			}),
			default: [],
			fallbackValue: [],
			outlineOptions: {
				displayChildren: true,
				allowAddElement: true
			},
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.text.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.text.constant.props.value.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.text.constant.props.value.constant.value,
					icon: "mdi/format-list-text"
				};
			}
		}),
		20,
		propGroups.common
	),

	style: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.propertyItem.style,
			options: getValuesFromStringEnum(
				PROPERTY_ITEM_STYLE,
				termsEditor.schemas.propertyItem.styleValues
			),
			default: PROPERTY_ITEM_STYLE_default
		}),
		30,
		propGroups.style
	)
};

const HAEComponentPropertyItem_Events = {
	...BaseEvents
};

const HAEComponentPropertyItem_Definition = defineElementaryComponent<
	typeof HAEComponentPropertyItem_Props,
	HAEComponentPropertyItem_State,
	typeof HAEComponentPropertyItem_Events
>({
	...termsEditor.components.propertyItem.component,

	name: "propertyItem",

	category: "content",

	icon: "mdi/format-list-text",

	docUrl: "...",

	order: 70,

	props: HAEComponentPropertyItem_Props,

	events: HAEComponentPropertyItem_Events,

	resolve: (spec, state) => {
		return (
			state || {
				...state,
				value: ""
			}
		);
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const itemValueClassNames = {
	root: "cmp-property-item__value-content",
	text: "cmp-property-item__value-text",
	button: "cmp-property-item__value-button",
	copyButton: "button cmp-property-item__value-button--copy",
	togglePasswordButton: "button cmp-property-item__value-button--toggle-password"
};

const itemClassNames = {
	root: "cmp-property-item__value",
	prefix: "cmp-property-item__value-prefix",
	suffix: "cmp-property-item__value-suffix",
	value: itemValueClassNames
};

const classNames = {
	root: "cmp-property-item",
	label: "cmp-property-item__label",
	item: itemClassNames
};

const HAEComponentPropertyItem_React: THAEComponentReact<typeof HAEComponentPropertyItem_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const items = Array.isArray(props.items) ? props.items.filter((item) => isNonEmptyObject(item)) : [];

	const componentPath = componentInstance.safePath;

	const [ visiblePasswords, setVisiblePasswords ] = React.useState(new Set());

	const labelHorizontalAlignValue = React.useMemo(
		() => getStringEnumValue(HORIZONTAL_ALIGN, props.label.horizontalAlign, HORIZONTAL_ALIGN_default),
		[ props.label.horizontalAlign ]
	);

	const labelPositionValue = React.useMemo(
		() => getStringEnumValue(LABEL_POSITION, props.label.position, LABEL_POSITION_default),
		[ props.label.position ]
	);

	const labelValueWidth = React.useMemo(
		() =>
			labelPositionValue === LABEL_POSITION.LEFT && props.label.width
				? `calc(100% - ${props.label.width})`
				: "100%",
		[ props.label.width, labelPositionValue ]
	);

	const styleValue = React.useMemo(
		() => getStringEnumValue(PROPERTY_ITEM_STYLE, props.style, PROPERTY_ITEM_STYLE_default),
		[ props.style ]
	);

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		classNames.root,
		componentInstance.safePath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		style: styleValue,
		"label-position": labelPositionValue
	});

	const labelClassList = new ClassList(classNames.label);

	labelClassList.addModifiers({
		"horizontal-align": labelHorizontalAlignValue
	});

	const prefixClassList = new ClassList(classNames.item.prefix);
	const suffixClassList = new ClassList(classNames.item.suffix);

	const propertyItemValueClassLists: IPropertyItemValueClassLists = {
		root: new ClassList(classNames.item.value.root),
		text: new ClassList(classNames.item.value.text),
		button: new ClassList(classNames.item.value.button),
		copyButton: new ClassList(classNames.item.value.button, classNames.item.value.copyButton),
		togglePasswordButton: new ClassList(
			classNames.item.value.button,
			classNames.item.value.togglePasswordButton
		)
	};

	const _valueTogglePasswordVisibilityHandler = React.useCallback(
		(itemIndex: string | number) => {
			setVisiblePasswords((prevState) => {
				if (prevState.delete(itemIndex)) {
					return new Set(prevState);
				}

				return new Set(prevState.add(itemIndex));
			});
		},
		[ setVisiblePasswords ]
	);

	const hasLabel = !!(props?.label?.text?.value || props?.label?.icon?.source);
	const RootElementType = hasLabel ? "dl" : "div";
	const ItemElementType = hasLabel ? "dd" : "div";

	return (
		<RootElementType className={classList.toClassName()} id={idClassName}>
			{hasLabel && (
				<Label
					{...props.label}
					tagName="dt"
					componentPath={[ ...componentPath, "label" ]}
					componentMode={componentInstance.componentMode}
					classList={labelClassList}
					inlineStyle={{ width: props.label.width }}
				/>
			)}

			{items.map((item, index) => {

				function _itemClickHandler() {
					if (componentInstance.eventEnabled.click) {
						return componentInstance.eventTriggers.click((parentScope) => {
							return createSubScope(parentScope, { item, index }, { item: Type.Any({}), index: Type.Integer({}) });
						});
					}

					return;
				}

				return (
					<ItemElementType
						key={item?.text?.value || index}
						className={classNames.item.root}
						style={{
							...(labelValueWidth ? { width: labelValueWidth } : {}),
							...(labelPositionValue === LABEL_POSITION.LEFT && index > 0
								? { marginLeft: props.label.width }
								: {})
						}}
					>
						<PropertyItemAdditionalText
							text={item.prefixText}
							componentPath={[ ...componentPath, "prefix" ]}
							componentMode={componentInstance.componentMode}
							classList={prefixClassList}
							width={item.prefixWidth}
						/>
						<PropertyItemValue
							id={index}
							componentPath={[ ...componentPath, "value" ]}
							componentMode={componentInstance.componentMode}
							mode={getStringEnumValue(PROPERTY_ITEM_VALUE_MODE, item.mode)}
							classLists={propertyItemValueClassLists}
							text={item.text}
							copy={item.copy}
							onPasswordVisibilityToggle={_valueTogglePasswordVisibilityHandler}
							passwordVisible={visiblePasswords.has(index)}
							onClick={_itemClickHandler}
						/>
						<PropertyItemAdditionalText
							text={item.suffixText}
							componentPath={[ ...componentPath, "suffix" ]}
							componentMode={componentInstance.componentMode}
							classList={suffixClassList}
							width={item.suffixWidth}
						/>
					</ItemElementType>
				)}
			)}
		</RootElementType>
	);
};

export const HAEComponentPropertyItem: THAEComponentDefinition<typeof HAEComponentPropertyItem_Definition> = {
	...HAEComponentPropertyItem_Definition,
	reactComponent: HAEComponentPropertyItem_React
};
