/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	OBJECT_TYPE,
	OBJECT_TYPE_PROP_NAME
} from "@hexio_io/hae-lib-blueprint";

/**
 * Timer Datasource Internal State
 */

interface DataSourceTimer_State {
	// Config
	interval: number;
	enabled: boolean;
	provideScopeValue: boolean;

	// Internal
	timeout: ReturnType<typeof setTimeout>|null;

	// Public
	tick: number|null;
}

export const DataSourceTimer_Opts = {
	enabled: BP.Prop(
		BP.Boolean({
			label: "Enabled",
			description: "Enable or disable the timer",
			constraints: {
				required: true
			},
			default: false,
			fallbackValue: false
		})
	),
	intervalMs: BP.Prop(
		BP.Integer({
			label: 'Interval (ms)',
			description: 'Interval in milliseconds',
			constraints: {
				required: true,
				min: 10
			},
			default: 1000,
			fallbackValue: 0
		})
	),
	provideScopeValue: BP.Prop(
		BP.Boolean({
			label: 'Provide Scope Value',
			description: 'When enabled, the `tick` value will be provided to the scope and incremented on each interval. Use with care, as this will case re-renders on each tick.',
			constraints: {
				required: false
			},
			default: false,
			fallbackValue: false
		})
	)
};

export const DataSourceTimer_Events = {
	tick: {
		label: 'On Tick',
		icon: "mdi/clock"
	}
};

/**
 * Timer Datasource
 */
export const DataSourceTimer = defineElementaryDataSource<
	typeof DataSourceTimer_Opts,
	DataSourceTimer_State,
	typeof DataSourceTimer_Events
>({
	name: "timer",
	label: "Timer",
	description: "Timer data source emits events on a regular interval.",
	icon: "mdi/clock",
	opts: DataSourceTimer_Opts,
	events: DataSourceTimer_Events,
	resolve: (opts, prevState, updateStateAsync, dsInstance, rCtx) => {
		// Disable in SSR
		if (rCtx.isInSSR()) {
			return {
				enabled: opts.enabled,
				interval: opts.intervalMs,
				provideScopeValue: opts.provideScopeValue,
				tick: null,
				timeout: null
			};
		}

		let state = prevState ?? {
			enabled: opts.enabled,
			interval: opts.intervalMs,
			provideScopeValue: opts.provideScopeValue,
			tick: opts.provideScopeValue ? 0 : null,
			timeout: null
		};

		const hasConfigChanged = (
			state.enabled !== opts.enabled ||
			state.interval !== opts.intervalMs ||
			state.provideScopeValue !== opts.provideScopeValue
		);

		if (hasConfigChanged) {
			state = {
				...state,
				enabled: opts.enabled,
				interval: opts.intervalMs,
				provideScopeValue: opts.provideScopeValue,
			}
		}

		// (Re)init timer
		if (state.enabled && (!state.timeout || hasConfigChanged)) {
			if (prevState?.timeout) {
				clearTimeout(prevState.timeout);
			}

			return {
				enabled: opts.enabled,
				interval: opts.intervalMs,
				provideScopeValue: opts.provideScopeValue,
				tick: opts.provideScopeValue ? 0 : null,
				timeout: setInterval(() => {
					if (opts.provideScopeValue) {
						updateStateAsync(prevState => ({
							...prevState,
							tick: prevState.tick + 1
						}));
					}

					if (dsInstance.eventEnabled.tick) {
						dsInstance.eventTriggers.tick(scope => scope);
					}
				}, opts.intervalMs)
			};
		}

		// Disable timer
		if (!state.enabled && state.timeout) {
			clearTimeout(state.timeout);
			
			return {
				...state,
				timeout: null,
				tick: 0
			}
		}

		return state;
	},

	destroy: (_opts, state) => {
		if (state.timeout) {
			clearTimeout(state.timeout);
			state.timeout = null;
		}
	},

	getScopeData: (opts, state) => {
		return {
			[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE,
			tick: state.tick
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				tick: Type.Integer({
					label: "Tick Number",
				})
			}
		});
	}
});
